<template>
  <nuxt-link
    :to="path"
    :data-testid="`${subItem.alias}-sub-item`"
    class="subItem flex cursor-pointer select-none items-center gap-x-3 p-2 text-xs font-bold leading-none text-white text-opacity-60 hover:rounded-lg"
    :class="{
      'active bg-p-blue-600 rounded-lg text-opacity-80': isActive,
      '!px-2.5': !isOpenSideNav,
    }"
  >
    <component
      :is="icon"
      v-if="subItem.icon !== undefined"
      :class="{ 'text-rawMaterialDemand-lithium': isActive }"
    />
    <span v-if="isOpenSideNav">{{ subItem.name }}</span>
  </nuxt-link>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useGeneralStore } from '~/stores/general'
import { useMenuStore } from '~/stores/menu'

const generalStore = useGeneralStore()
const { isOpenSideNav } = storeToRefs(generalStore)

const menuStore = useMenuStore()
const { activeSubItem, activeItem } = storeToRefs(menuStore)

const props = defineProps<{
  subItem: any
}>()

const icon = defineAsyncComponent(
  () => import(`../../../assets/icons/menu/${props.subItem.icon}.svg?component`),
)

const isActive = computed(() => {
  const newAlias = props.subItem.alias.replace('admin-', '')
  const activeItemAlias = `${activeItem.value}-${activeSubItem.value}`
  return newAlias === activeItemAlias
})
const path = computed(() => {
  if (!props.subItem.path) return ''

  return props.subItem.path
})
</script>

<style scoped>
.subItem :deep(svg) {
  height: 16px;
  width: auto;
}

.subItem:hover {
  background: linear-gradient(0deg, rgb(255 255 255 / 5%), rgb(255 255 255 / 5%)), #071830;
}
</style>
