<template>
  <div>
    <div class="py-3 flex justify-end px-3" :class="{ 'justify-center': !isOpenSideNav }">
      <div
        class="arrow-container flex h-6 w-6 cursor-pointer items-center justify-center rounded-sm text-p-yellow-600"
        :class="!isOpenSideNav ? 'arrow-elevation-close rotate-180' : 'arrow-elevation'"
        data-test="flyout-sidebar-reports-toggle"
        @click="toggleSideNav"
      >
        <ArrowLeft class="h-4 w-4" />
      </div>
    </div>
    <nuxt-link class="pb-4 pt-1 flex justify-center min-h-[60px]" :to="urlLogo">
      <BenchmarkLogo class="w-full h-28 px-4" :class="{ 'mix-blend-luminosity': isAdminMode }" />
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import BenchmarkLogo from '~/assets/icons/logo-benchmark_pro.svg'
import ArrowLeft from '~/assets/icons/arrow-left.svg'
import useMenu from '~/composables/useMenu'
import { useGeneralStore } from '~/stores/general'

const generalStore = useGeneralStore()
const { toggleSideNav } = generalStore
const { isOpenSideNav, isAdminMode } = storeToRefs(generalStore)

const { getFirstUrl } = useMenu()

const urlLogo = computed(() => getFirstUrl())
</script>

<style scoped>
.arrow-container {
  box-shadow: inset -1px 1px 4px rgb(7 24 48 / 82%);
}

.arrow-elevation {
  background: linear-gradient(0deg, rgb(255 255 255 / 5%), rgb(255 255 255 / 5%)), #071830;
}

.arrow-elevation-close {
  background: linear-gradient(0deg, rgb(255 255 255 / 16%), rgb(255 255 255 / 16%)), #071830;
  transform: rotate(-180deg);
}
</style>
