<template>
  <nav class="flex flex-col gap-y-1 px-1.5">
    <SideBarItem
      v-for="item in menu"
      :key="item.pathObject"
      :item="item"
      :default-open="lithiumItemShouldBeOpen(item)"
    />
  </nav>
</template>

<script setup lang="ts">
import { useRoute, useNuxtApp } from 'nuxt/app'
import { onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { AdminMenuData, MenuData } from '~/constants/menu'
import SideBarItem from '~/components/layout/SideBar/SideBarItem.vue'
import useMenu from '~/composables/useMenu'
import { useGeneralStore } from '~/stores/general'
import { useMenuStore } from '~/stores/menu'

const { $authorization } = useNuxtApp()

const generalStore = useGeneralStore()
const { setIsAdminMode } = generalStore
const { isAdminMode } = storeToRefs(generalStore)

const { filterMenu } = useMenu()
const route = useRoute()

const menuStore = useMenuStore()
const { setActiveItem, setActiveSubItem, setMenu } = menuStore
const { menu } = storeToRefs(menuStore)

const getActiveRoute = () => {
  const path = isAdminMode.value ? route.path.replace('/admin', '') : route.path
  const [activeItem, activeSubItem] = path.split('/').filter((item) => item && item !== '')
  return { activeItem, activeSubItem }
}

const setActiveItems = (path) => {
  const routesArray = path.split('/')
  const isAdminMode = routesArray.includes('admin')

  setIsAdminMode(isAdminMode)

  const { activeSubItem, activeItem } = getActiveRoute()

  setActiveItem(activeItem)
  setActiveSubItem(activeSubItem)
}

const getMenu = () => {
  if (menu.value && menu.value.length > 0) return menu.value

  const data = isAdminMode.value ? AdminMenuData : MenuData
  const menuObject = filterMenu(data)

  return Object.values(menuObject).map((item: string) => {
    if (!item.items) return item

    const items = Object.values(item.items)

    return { ...item, items }
  })
}

const lithiumItemShouldBeOpen = (item) => {
  return item.alias === 'lithium' && $authorization.hasOnlyLithiumReadRole()
}

watch(
  () => route.fullPath,
  (fullPath) => {
    setActiveItems(fullPath)
  },
)

watch(isAdminMode, () => {
  setMenu([])
  const menu = getMenu()
  setMenu(menu)
})

onMounted(() => {
  setActiveItems(route.fullPath)
  const menu = getMenu()
  setMenu(menu)
})
</script>
