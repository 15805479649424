<template>
  <div
    class="flex flex-col gap-y-1"
    :class="{ 'items-center': !isOpenSideNav }"
    :data-testid="`${item.alias}-item`"
  >
    <component
      :is="getComponent"
      v-bind="getProps"
      class="item hover:bg-p-blue-600 flex cursor-pointer select-none items-center justify-between rounded-lg py-2 pl-2.5 pr-2 text-xs font-bold leading-[normal] text-white text-opacity-60"
      :class="{
        'active bg-p-blue-600 text-opacity-80': isActive,
        'w-fit !px-2': !isOpenSideNav,
        open: open,
      }"
      @click="showSubItems"
    >
      <div class="flex items-center gap-x-4">
        <component
          :is="icon"
          v-if="item.icon !== undefined"
          :class="{ 'text-rawMaterialDemand-lithium': isActive }"
        />
        <span v-if="isOpenSideNav">{{ item.name }}</span>
      </div>
      <ChevronDown
        v-if="hasSubItems && isOpenSideNav"
        class="duration-300"
        :class="{ 'text-rawMaterialDemand-lithium': isActive, 'rotate-180': open }"
      />
    </component>
    <div
      class="transition-max-height flex flex-col gap-y-1 overflow-hidden px-9 duration-300 ease-in-out"
      :class="[open ? 'max-h-[300px]' : 'max-h-0', { 'items-center !px-0': !isOpenSideNav }]"
    >
      <SideBarSubItem v-for="subItem in subItems" :key="subItem.alias" :sub-item="subItem" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, ref, computed, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import ChevronDown from '~/assets/icons/ChevronDown.svg'
import SideBarSubItem from '~/components/layout/SideBar/SideBarSubItem.vue'
import { useGeneralStore } from '~/stores/general'
import { useMenuStore } from '~/stores/menu'

const generalStore = useGeneralStore()
const { isOpenSideNav } = storeToRefs(generalStore)

const menuStore = useMenuStore()
const { activeItem, activeSubItem, openByDefault } = storeToRefs(menuStore)

const props = withDefaults(
  defineProps<{
    item: any
    defaultOpen?: boolean
  }>(),
  {
    defaultOpen: false,
  },
)

const open = ref(false)

const isActive = computed(() => {
  return props.item.alias.includes(activeItem.value)
})
const hasSubItems = computed(() => {
  return props.item.items?.length > 0
})
const subItems = computed(() => {
  if (!hasSubItems.value) return []

  return props.item.items
})

const icon = defineAsyncComponent(
  () => import(`../../../assets/icons/menu/${props.item.icon}.svg?component`),
)

const showSubItems = () => {
  if (!hasSubItems.value) return

  open.value = !open.value
}

const getComponent = computed(() => {
  if (hasSubItems.value) return 'div'
  return resolveComponent('NuxtLink')
})

const getProps = computed(() => {
  if (hasSubItems.value) return {}
  return { to: props.item.path }
})

const itemShouldBeOpen = computed(() => {
  return openByDefault.value === props.item.alias
})

watch(activeItem, () => {
  open.value = itemShouldBeOpen.value || isActive.value
})

watch(activeSubItem, () => {
  open.value = itemShouldBeOpen.value || isActive.value
})

onMounted(() => {
  open.value = props.defaultOpen || isActive.value
})
</script>

<style scoped>
.item :deep(svg) {
  height: 16px;
  width: auto;
}
</style>
