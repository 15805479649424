<template>
  <div
    class="bg-nav-box overflow-y-auto overflow-x-hidden"
    @mouseenter="mouseEnterMenu"
    @mouseleave="mouseLeaveMenu"
  >
    <div class="flex h-full flex-col">
      <SideBarHead />
      <SideBarNav />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGeneralStore } from '~/stores/general'

const generalStore = useGeneralStore()
const { toggleSideNav } = generalStore

const emit = defineEmits<{
  hovered: [val: boolean]
}>()

const sideBarHovered = ref(false)

const mouseEnterMenu = () => {
  sideBarHovered.value = true
}

const mouseLeaveMenu = () => {
  sideBarHovered.value = false
}

onMounted(() => {
  if (window && window.innerWidth < 1366) {
    toggleSideNav()
  }
})

watch(sideBarHovered, (newVal) => {
  emit('hovered', newVal)
})
</script>
